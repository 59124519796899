<script setup lang="ts">
import { Back } from "@element-plus/icons-vue";
import { UserRoles } from "@/shared/enums";
const { data: user } = useAuth();
const route = useRoute();
const activeIndex = computed<string>(() => {
  const path = route.path;
  if (path.includes("/trade/")) return "/trade";
  return path;
});
const localePath = useLocalePath();
</script>

<template>
  <el-row :gutter="20" class="main-header-row" align="middle">
    <el-col :span="-1">
      <NuxtLink :to="localePath('/')">
        <el-button link type="primary" :icon="Back">Go to main site</el-button>
      </NuxtLink>
    </el-col>
    <div class="flex-grow"></div>
    <el-col :span="-1">
      <el-menu
        :default-active="activeIndex"
        mode="horizontal"
        background-color="transparent"
        :ellipsis="false"
        router
      >
        <el-menu-item
          :index="localePath('/administration/exchange/setup')"
          v-if="user?.role === UserRoles.Admin"
        >
          Exchange setup
        </el-menu-item>
        <el-menu-item
          :index="localePath('/administration/stats')"
          v-if="user?.role === UserRoles.Admin"
        >
          Statistic
        </el-menu-item>
        <el-menu-item
          :index="localePath('/administration/users')"
          v-if="user?.role === UserRoles.Moderator"
        >
          Users
        </el-menu-item>
        <el-menu-item
          :index="localePath('/administration/exchange/requests')"
          v-if="user?.role === UserRoles.Moderator"
        >
          Exchange requests
        </el-menu-item>
        <el-menu-item
          :index="localePath('/administration/disputes')"
          v-if="user?.role === UserRoles.Moderator"
        >
          Disputes
        </el-menu-item>
        <el-menu-item
          :index="localePath('/administration/chats')"
          v-if="user?.role === UserRoles.Moderator"
        >
          Chats
        </el-menu-item>
      </el-menu>
    </el-col>
    <el-col :span="-1">
      <Notifications />
    </el-col>
  </el-row>
</template>

<style lang="scss" scoped>
.main-header-row {
  min-height: 60px;
}
</style>
